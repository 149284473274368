<template>
    <b-card>
        <b-tabs pills>
            <b-tab active>
                <template #title>
                    <feather-icon icon="UsersIcon"/>
                    <span>Genel Bilgiler</span>
                </template>
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
                        <b-row class="mt-1">
                            <b-col cols="12" md="6" lg="6">
                                <b-row>
                                    <b-col cols="12">
                                        <validation-provider #default="validationContext" name="İsim" rules="required">
                                            <b-form-group label="İsim">
                                                <b-form-input trim placeholder="İsim" v-model="dataInfo.firstName" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12">
                                        <validation-provider #default="validationContext" name="Soyisim" rules="required">
                                            <b-form-group label="Soyisim">
                                                <b-form-input trim placeholder="Soyisim" v-model="dataInfo.lastName" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12">
                                        <validation-provider #default="validationContext" name="Telefon" rules="required">
                                            <b-form-group label="Telefon">
                                                <b-form-input trim placeholder="Telefon" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12">
                                        <validation-provider #default="validationContext" name="E-Posta" rules="required|email">
                                            <b-form-group label="E-Posta">
                                                <b-form-input trim placeholder="E-Posta" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Kullanıcı Rolü" rules="required">
                                            <b-form-group label="Kullanıcı Rolü" :state="getValidationState(validationContext)">
                                                <v-select v-model="userRole" :options="userRolesOptions" :reduce="val => val.value" :clearable="false" v-on:input="getUserTypeValues"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12">
                                        <validation-provider #default="validationContext" name="Durum" rules="required">
                                            <b-form-group label="Durum" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12" md="6" lg="6">
                                <b-row>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Kullanıcı Tipi" rules="required">
                                            <b-form-group label="Kullanıcı Tipi" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.userType" :options="userTypeOptions" :reduce="val => val.value" :clearable="false" v-on:input="getUserTypeValues"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Kullanıcı Tip Değeri">
                                            <b-form-group label="Kullanıcı Tip Değeri" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.userTypeValues" :options="userTypeValueOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Üst Kullanıcı">
                                            <b-form-group label="Üst Kullanıcı" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.parentUserId" :options="parentUserOptions" :reduce="val => val.value" :clearable="true"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Müşteri Temsilcisi mi?" rules="required">
                                            <b-form-group label="Müşteri Temsilcisi mi?" :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.isSalesman" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12" lg="12">
                                        <validation-provider #default="validationContext" name="Erişim Bilgileri">
                                            <b-form-group label="Erişim Bilgileri" description="Erişim bilgileri belirtilmezse tüm kayıtlar görüntülenecektir." :state="getValidationState(validationContext)">
                                                <v-select v-model="dataInfo.userAccessDefinitions" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <div class="mt-1 d-flex justify-content-center align-content-end">
                                    <b-avatar
                                            ref="previewEl"
                                            :src="(dataInfo.profilePictureBase64 ? dataInfo.profilePictureBase64 : getApiFile(dataInfo.profilePicture))"
                                            :text="avatarText(dataInfo.firstName + ' ' + dataInfo.lastName)"
                                            button
                                            variant="primary"
                                            size="70px"
                                            @click="$refs.refInputEl.click()"/>
                                    <input
                                            ref="refInputEl"
                                            type="file"
                                            class="d-none"
                                            @input="inputImageRenderer">
                                </div>
                            </b-col>
                        </b-row>
                        <action-buttons :back-route="'system-user-list'"/>
                    </b-form>
                </validation-observer>
            </b-tab>
            <DeviceList></DeviceList>
        </b-tabs>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/user/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils"
import salesmanModule from "@/views/definition/salesman/store"
import customerModule from "@/views/definition/customer/store"
import accessModule from "@/views/definition/access/store"
import DeviceList from "@/views/system/user/device/List.vue";
import {$themeConfig} from "@themeConfig";

export default {
    components: {
        BTab,
        BTabs,
        BAvatar,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,

        DeviceList,
        vSelect,
        ActionButtons,
        Overlay,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_SALESMAN_MODULE_NAME = 'store-salesman'
        const STORE_CUSTOMER_MODULE_NAME = 'store-customer'
        const STORE_ACCESS_MODULE_NAME = 'store-access'

        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_SALESMAN_MODULE_NAME, salesmanModule)
            store.registerModule(STORE_CUSTOMER_MODULE_NAME, customerModule)
            store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_SALESMAN_MODULE_NAME)
                store.unregisterModule(STORE_CUSTOMER_MODULE_NAME)
                store.unregisterModule(STORE_ACCESS_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)
        const userTypeValueOptions = ref([])

        const dataInfo = ref({
            id: 0,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            profilePicture: '',
            profilePictureBase64: null,
            userType: 0,
            parentUserId: null,
            isActive: true,
            isSalesman: false,
            roles: [],
            userTypeValues: [],
            userAccessDefinitions: []
        })

        const userTypeOptions = [
            {label: 'Sistem', value: 0},
            {label: 'Temsilci', value: 1},
            {label: 'Müşteri', value: 2},
        ]

        const userRole = ref(null)
        const userRolesOptions = ref([])

        const previewEl = ref(null)
        const refInputEl = ref(null)
        const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
            dataInfo.value.profilePictureBase64 = base64
        })

        const onSubmit = () => {
            if (!$themeConfig.app.appIsDemo) {
                busy.value = true
                dataInfo.value.roles.forEach(function callback(value, index) {
                    value.isAuthorized = value.id === userRole.value
                })
                store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                    toastMessage(toast, 'success', response.data.message)
                    router.push({name: 'system-user-list'})
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                }).finally(message => {
                    busy.value = false
                })
            } else {
                toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
            }
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        const getUserTypeValues = (val) => {
            userTypeValueOptions.value = []

            if (val != null) {
                if (val === 0) {
                    dataInfo.value.userTypeValues = []
                } else if (val === 1) {
                    //Salesman
                    busy.value = true
                    store.dispatch('store-salesman/fetchItems').then(response => {
                        response.data.data.forEach((value, index) => {
                            userTypeValueOptions.value.push({label: value.name, value: value.id})
                        });
                    }).catch(error => {
                        toastMessage(toast, 'danger', error.response.data.message)
                    }).finally(message => {
                        busy.value = false
                    })
                } else {
                    //Customer
                    busy.value = true
                    store.dispatch('store-customer/fetchItems').then(response => {
                        response.data.data.forEach((value, index) => {
                            userTypeValueOptions.value.push({label: value.name, value: value.id})
                        });
                    }).catch(error => {
                        toastMessage(toast, 'danger', error.response.data.message)
                    }).finally(message => {
                        busy.value = false
                    })
                }
            } else {
                dataInfo.value.userTypeValues = []
            }
        }

        busy.value = true
        store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
            dataInfo.value = response.data.data
            if (dataInfo.value.userType > 0) {
                getUserTypeValues(dataInfo.value.userType)
            }

            dataInfo.value.roles.forEach((value, index) => {
                userRolesOptions.value.push({label: value.name, value: value.id})
                if (value.isAuthorized) {
                    userRole.value = value.id
                }
            });

        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'system-user-list'})
            }
        }).finally(message => {
            busy.value = false
        })

        const parentUserOptions = ref([])
        busy.value = true
        store.dispatch('store/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.id !== router.currentRoute.params.id && value.isActive) {
                    parentUserOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id})
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        const accessDefinitionOptions = ref([])
        busy.value = true
        store.dispatch('store-access/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.isActive) {
                    accessDefinitionOptions.value.push({label: value.name, value: value.id})
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        return {
            busy,
            dataInfo,
            previewEl,
            refInputEl,
            refFormObserver,
            userTypeOptions,
            userTypeValueOptions,
            accessDefinitionOptions,
            parentUserOptions,
            userRole,
            userRolesOptions,

            statusOptions,
            yesNoOptions,

            onSubmit,
            getValidationState,
            resetForm,
            getApiFile,
            avatarText,
            inputImageRenderer,
            getUserTypeValues,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
